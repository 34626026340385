import { UserContext } from 'providers/UserProvider';
import { useContext, useEffect } from 'react';

export default function useTitle(...titles) {
  const user = useContext(UserContext);
  const { organization } = user;

  const { isCodeCosmosB2C, isCodefever } = organization;
  let brand = 'FTRPRF';
  if (isCodeCosmosB2C) brand = 'CodeCosmos';
  if (isCodefever) brand = 'CodeFever';

  useEffect(() => {
    const previousTitle = window.document.title;
    window.document.title =
      titles.filter(Boolean).length > 0
        ? `${brand} - ${titles.join(' - ')}`
        : brand;

    return () => {
      window.document.title = previousTitle;
    };
  }, [titles, brand]);
}

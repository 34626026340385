import { IDPS } from './constants/idp';
import issuers from './constants/issuers';

export const STUDENT = 'STUDENT';
export const TEACHER = 'TEACHER';
export const SCHOOLADMIN = 'SCHOOLADMIN';
export const ADMIN = 'ADMIN';

/**
 * You can use this function to determine the roles of the organisation.
 * Add additional rolesConditions to this file.
 *
 * @param {organisationObject} organization
 * @returns determined organisation roles based on organisation object
 */
export function determineOrganisationRoles(organization) {
  const roleConditions = {
    ...organization,
    isBeta: false,
    isSteams: false,
    isDemo: false,
    isCodefever: false,
    isCodecosmos: false,
    isCodeCosmosB2C: false,
    isILearn: false,
    isFTRPRF: false,
    isSmartschool: false,
    isTeams: false,
  };

  if (!organization) {
    return roleConditions;
  }

  switch (organization.idp) {
    case IDPS.CODEFEVER:
      roleConditions.isCodefever = true;
      break;
    case IDPS.CODECOSMOS:
      roleConditions.isCodecosmos = true;
      if (organization.name.indexOf('B2C') !== -1) {
        roleConditions.isCodeCosmosB2C = true;
      }
      break;
    case IDPS.ILEARN:
      roleConditions.isILearn = true;
      break;
    case IDPS.FTRPRF:
      roleConditions.isFTRPRF = true;
      break;
    case IDPS.SMARTSCHOOL:
      roleConditions.isSmartschool = true;
      break;
    case IDPS.TEAMS:
      roleConditions.isTeams = true;
      break;
    default:
      break;
  }

  roleConditions.isSmartschool = organization?.issuer === issuers.SMARTSCHOOL;
  roleConditions.isTeams = organization?.issuer === issuers.TEAMS;
  roleConditions.isBeta = organization.beta;
  roleConditions.isSteams = organization.steams;
  roleConditions.isDemo = organization.demo;

  return roleConditions;
}
